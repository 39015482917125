import React from 'react';
import { graphql, type PageProps } from 'gatsby';

import { Layout } from 'modules/ui';
import { GlossaryItemPage, BlogSeo } from 'modules/blog';
import type { BlogPost, GlossaryItem } from 'modules/blog/types';

interface DataProps {
    strapiGlossary: GlossaryItem;
    allStrapiPost: {
        nodes: BlogPost[];
    };
}

const BlogGlossaryItem = ({ data }: PageProps<DataProps>) => {
    const { seo, ...glossary } = data.strapiGlossary;

    return (
        <>
            <BlogSeo
                title={seo?.metaTitle ?? glossary.title}
                description={seo?.metaDescription}
                keywords={seo?.keywords}
                titleTemplate="%s"
            />
            <Layout>
                <GlossaryItemPage {...data.strapiGlossary} otherPosts={data.allStrapiPost.nodes} />
            </Layout>
        </>
    );
};

export const pageQuery = graphql`
    query ($id: String!) {
        strapiGlossary(id: { eq: $id }) {
            ...GlossaryItem
        }
        allStrapiPost(sort: { fields: publishedAt, order: DESC }, limit: 3) {
            nodes {
                ...BlogPost
            }
        }
    }
`;

export default BlogGlossaryItem;
